@use 'sass:math' as math;
@use '../utils' as utils;

$spacing-none: utils.to-rem(0px);
$spacing-3xs: utils.to-rem(4px);
$spacing-2xs: utils.to-rem(8px);
$spacing-xs: utils.to-rem(12px);
$spacing-sm: utils.to-rem(16px);
$spacing-md: utils.to-rem(24px);
$spacing-lg: utils.to-rem(40px);
$spacing-xl: utils.to-rem(48px);
$spacing-2xl: utils.to-rem(72px);
$spacing-3xl: utils.to-rem(96px);
$spacing-4xl: utils.to-rem(114px);

$size-to-spacing-variable: (
  'None': $spacing-none,
  '3xs': $spacing-3xs,
  '2xs': $spacing-2xs,
  'Xs': $spacing-xs,
  'Sm': $spacing-sm,
  'Md': $spacing-md,
  'Lg': $spacing-lg,
  'Xl': $spacing-xl,
  '2xl': $spacing-2xl,
  '3xl': $spacing-3xl,
  '4xl': $spacing-4xl,
);
