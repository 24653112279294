@use '../../reference/styles' as styles;

input[type='text'] {
  &.search {
    font-family: Sans-Regular;
    font-size: 16px;
    background-color: styles.$darker-grey-60;
    color: styles.$white;
    width: 98%;
    border: none;
    border-radius: styles.$corners-sm;
    padding: 15px;

    @media (max-width: 768px) {
      font-size: 12px;
      padding: 5px;
    }
  }
}

select {
  &.search {
    font-family: Sans-Regular;
    font-size: 16px;
    background-color: styles.$dark-grey;
    color: styles.$white;
    width: 100%;
    border: none;
    border-radius: styles.$corners-sm;
    cursor: pointer;
    padding: 5px;
  }
}
