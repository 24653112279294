.leaflet-container {
  width: 100%;
  height: 100vh;
}

.currLoc {
  position: relative;
  display: inline-block;
}

.currLoc .currLocHover {
  visibility: hidden;
  width: 240px;
  color: #fff;
  text-align: center;
  padding: 0;
  position: absolute;
  /* z-index: 1; */
}

.currLoc:hover .currLocHover {
  visibility: visible;
}

#searchRoute {
  height: 100%;
  float: right;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  height: 100vh;
}
