@use '../../reference/styles' as styles;

.background {
  width: 100%;
  height: 100%;
}

.color {
  &Black {
    background-color: styles.$black;
  }

  &DarkerGrey {
    background-color: styles.$darker-grey;
  }

  &DarkGrey {
    background-color: styles.$dark-grey;
  }

  &MediumGrey {
    background-color: styles.$medium-grey;
  }

  &LightGrey {
    background-color: styles.$light-grey;
  }

  &LighterGrey {
    background-color: styles.$lighter-grey;
  }

  &LightBlue {
    background-color: styles.$light-blue;
  }

  &White {
    background-color: styles.$white;
  }

  &Transparent {
    background-color: styles.$transparent;
  }
}
