@use './_index.scss' as reference;

// Fonts - Computer Modern Serif

@font-face {
  font-family: CMU-Serif;
  src: url(../../../utils/fonts/cmu/cmu-serif.ttf);
}

@font-face {
  font-family: CMU-Bold;
  src: url(../../../utils/fonts/cmu/cmu-bold.ttf);
}

@font-face {
  font-family: CMU-Italic;
  src: url(../../../utils/fonts/cmu/cmu-italic.ttf);
}

@font-face {
  font-family: CMU-Bold-Italic;
  src: url(../../../utils/fonts/cmu/cmu-bold-italic.ttf);
}

// Fonts - Open Sans Sans-Serif

@font-face {
  font-family: Sans-Light;
  src: url(../../../utils/fonts/open-sans/open-sans-light.ttf);
}

@font-face {
  font-family: Sans-Regular;
  src: url(../../../utils/fonts/open-sans/open-sans-regular.ttf);
}

@font-face {
  font-family: Sans-Bold;
  src: url(../../../utils/fonts/open-sans/open-sans-medium.ttf);
}

@font-face {
  font-family: Sans-Italic-Light;
  src: url(../../../utils/fonts/open-sans/open-sans-light-italic.ttf);
}

@font-face {
  font-family: Sans-Italic;
  src: url(../../../utils/fonts/open-sans/open-sans-italic.ttf);
}

@font-face {
  font-family: Sans-Bold-Italic;
  src: url(../../../utils/fonts/open-sans/open-sans-semi-bold-italic.ttf);
}

// Fonts - Audi Sans-Serif

@font-face {
  font-family: Audi-Regular;
  src: url(../../../utils/fonts/audi/audi-normal.ttf);
}

@font-face {
  font-family: Audi-Light;
  src: url(../../../utils/fonts/audi/audi-wide-light.ttf);
}

@font-face {
  font-family: Audi-Bold;
  src: url(../../../utils/fonts/audi/audi-bold.ttf);
}

@font-face {
  font-family: Audi-Italic;
  src: url(../../../utils/fonts/audi/audi-italic.ttf);
}

@font-face {
  font-family: Audi-Bold-Italic;
  src: url(../../../utils/fonts/audi/audi-bold-italic.ttf);
}

@font-face {
  font-family: Audi-Extended;
  src: url(../../../utils/fonts/audi/audi-extended-bold.ttf);
}

// Fonts - Other

@font-face {
  font-family: Instagram;
  src: url(../../../utils/fonts/grandista/grandista.ttf);
}

@font-face {
  font-family: NFS;
  src: url(../../../utils/fonts/nfs/nfs.ttf);
}

@font-face {
  font-family: IBM;
  src: url(../../../utils/fonts/ibm/ibm.ttf);
}

@font-face {
  font-family: Broadway;
  src: url(../../../utils/fonts/broadway/broadway-regular.ttf);
}

@font-face {
  font-family: GreatVibes;
  src: url(../../../utils/fonts/great-vibes/great-vibes.ttf);
}
