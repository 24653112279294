@use '../../reference/mixins' as mixins;

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.direction {
  &Horizontal {
    flex-direction: row;

    &.alignHorizontal {
      &Left {
        justify-content: flex-start;
      }

      &Center {
        justify-content: center;
      }

      &Right {
        justify-content: flex-end;
      }

      &Apart {
        justify-content: space-between;
      }
    }

    &.alignVertical {
      &Top {
        align-items: flex-start;
      }

      &Center {
        align-items: center;
      }

      &Bottom {
        align-items: flex-end;
      }

      &Stretch {
        align-items: stretch;
      }
    }
  }

  &Vertical {
    flex-direction: column;

    &.alignHorizontal {
      &Left {
        align-items: flex-start;
      }

      &Center {
        align-items: center;
      }

      &Right {
        align-items: flex-end;
      }

      &Stretch {
        align-items: stretch;
      }
    }

    &.alignVertical {
      &Top {
        justify-content: flex-start;
      }

      &Center {
        justify-content: center;
      }

      &Bottom {
        justify-content: flex-end;
      }

      &Apart {
        justify-content: space-between;
      }
    }
  }
}

$-prefix-to-properties: (
  'gap': 'gap',
  'rowGap': 'row-gap',
  'columnGap': 'column-gap',
);

@each $prefix, $properties in $-prefix-to-properties {
  @include mixins.responsive-spacing($prefix) using ($spacing-variable) {
    @each $property in $properties {
      #{$property}: $spacing-variable;
    }
  }
}

.fullHeight {
  height: 100%;
}
